

























import Vue from "vue";
import { db, Timestamp } from "@/firebase";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import { toCSVDate } from "@/filters";

import { defaultAccountCounts } from "@/typings/defaults/accounts";

import ActionButton from "@/components/ActionButton.vue";
import ErrorNotice from "@/components/ErrorNotice.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import NumberInput from "@/components/NumberInput.vue";
import ReportTable from "@/components/ReportTable.vue";

import { errorFromUnknownError } from "@/helpers/errorFromUnknownError";

interface ActiveAccountsResultRow {
  name?: string;
  id?: string;
  referrals?: number;
  bonusPayments?: number;
  superAdmins?: number;
  admins?: number;
  referringUsers?: number;
  startDate?: string;
}

export default Vue.extend({
  name: "ActiveAccountsReport",
  components: {
    ActionButton,
    NumberInput,
    ErrorNotice,
    LoadingIndicator,
    ReportTable
  },
  data: () => ({
    daysAgo: 30,
    rows: [] as ActiveAccountsResultRow[],
    error: null as Error | null,
    loading: false,
    reportRanAt: null as null | Date,
    showForm: true
  }),
  computed: {
    columns() {
      const columns = [
        { title: "Account", field: "name" },
        { title: "Account ID", field: "id" },
        { title: `# of Referrals in the past ${this.daysAgo} days`, field: "referrals" },
        { title: `# of Bonus Payments in the past ${this.daysAgo} days`, field: "bonusPayments" },
        { title: "# of Super-Admin Users", field: "superAdmins" },
        { title: "# of Admin Users", field: "admins" },
        { title: "# of Referring Users", field: "referringUsers" },
        { title: "Start Date", field: "startDate" }
      ];
      return columns;
    },
    comparableDate(): Date {
      return subDays(new Date(), this.daysAgo);
    },
    comparableDateFormatted(): string {
      return format(subDays(new Date(), this.daysAgo), "yyyy-MM-dd");
    }
  },
  methods: {
    async loadData(): Promise<void> {
      if (this.loading !== false) return;
      this.showForm = false;
      this.loading = true;
      this.error = null;
      const ts = Timestamp.fromDate(this.comparableDate);
      try {
        let newRows: Array<ActiveAccountsResultRow> = [];
        const accountRef = db.collection("accounts");
        await accountRef.get().then(querySnapshot => {
          querySnapshot.forEach(async account => {
            const accountId = account.id;
            const numPayments = (
              await db
                .collection("bonusPayments")
                .where("account", "==", accountId)
                .where("sent", ">=", ts)
                .get()
            ).docs.length;

            const numReferrals = (
              await db
                .collection("referrals")
                .where("account", "==", accountId)
                .where("createdAt", ">=", ts)
                .get()
            ).docs.length;

            if (numPayments === 0 && numReferrals === 0) {
              return;
            }
            const accountData = account.data() as AccountStoreEntry;
            const countsRef = db
              .collection("accounts")
              .doc(accountId)
              .collection("info")
              .doc("counts");
            const counts =
              ((await countsRef.get()).data() as Account.Counts) ?? defaultAccountCounts();
            const row: ActiveAccountsResultRow = {
              name: accountData.name,
              id: accountId,
              referrals: numReferrals,
              bonusPayments: numPayments,
              superAdmins: counts?.registeredUsers.super ?? 0,
              admins: counts?.registeredUsers.admin ?? 0,
              referringUsers: counts?.registeredUsers.normal ?? 0,
              startDate: toCSVDate(accountData.createdAt.toDate())
            };
            newRows.push(row);
            this.rows = newRows;
          });
        });
      } catch (error) {
        this.error = errorFromUnknownError(error);
      }
      this.loading = false;
    },
    runNewReport(): void {
      this.showForm = true;
      this.rows = [];
    }
  }
});
