/**
 * Converts an unknown value, such as one returned from a `catch` block, into an `Error instance`
 *
 * If `error` is an `Error` instance, that is returned directly. If `error` is a string, then that is used as the message for a new `Error`. Otherwise, the returned `Error`'s message is a stringified form of the given value.
 *
 * @example
 * ```ts
 * let err: Error;
 *
 * try {
 *   await doSomething()
 * } catch (error) {
 *   err = errorFromUnknownError(error)
 * }
 * ```
 */
export function errorFromUnknownError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  } else if (typeof error === "string") {
    return new Error(error);
  }
  return new Error(JSON.stringify(error));
}
