

















import type { PropType } from "vue";
import Vue from "vue";
import { errorFromUnknownError } from "@/helpers/errorFromUnknownError";
import { saveAs } from "file-saver";

import ActionButton from "./ActionButton.vue";
import DownloadIcon from "@/icons/Download.vue";
import ErrorNotice from "./ErrorNotice.vue";

export default Vue.extend({
  name: "DownloadButton",
  components: {
    ActionButton,
    DownloadIcon,
    ErrorNotice
  },
  props: {
    label: { type: String, default: "Download" },
    kind: { type: String, default: "primary" },
    fileName: { type: String, required: true },
    fit: { type: Boolean, default: false },
    downloadUrl: { type: String as PropType<string | null>, default: null },
    getDownloadUrl: { type: Function as PropType<(() => Promise<string>) | null>, default: null }
  },
  data: () => ({
    isDownloading: false,
    error: null as Error | null
  }),
  methods: {
    async onClick() {
      if (this.isDownloading === false) {
        this.error = null;
        this.isDownloading = true;
        try {
          let url = this.downloadUrl;
          console.log("URL", url);
          if (this.getDownloadUrl) {
            url = await this.getDownloadUrl();
            console.log("after get", url);
          }

          if (!url) {
            console.log("no url");
            throw new Error("Download URL is Empty");
          } else {
            saveAs(url, this.fileName);
          }
        } catch (error) {
          console.error(error);
          this.error = errorFromUnknownError(error);
        }
        this.isDownloading = false;
      }
    }
  }
});
