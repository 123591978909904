





















































































import Vue from "vue";
import type { PropType } from "vue";
import Fuse from "fuse.js";
import format from "date-fns/format";
import orderBy from "lodash/orderBy";
import omit from "lodash/omit";

import ActionButton from "./ActionButton.vue";
import Checkbox from "./Checkbox.vue";
import DownloadButton from "./DownloadButton.vue";
import Modal from "./Modal.vue";
import SearchInput from "./SearchInput.vue";

import DropdownIcon from "@/icons/Dropdown.vue";

interface ReportTableColumn {
  title: string;
  field: string;
}

interface ReportTableRow {
  [propName: string]: string;
}

export default Vue.extend({
  name: "ReportTable",
  components: {
    ActionButton,
    Checkbox,
    DropdownIcon,
    DownloadButton,
    Modal,
    SearchInput
  },
  props: {
    reportName: { type: String, default: "Report" },
    columns: { type: Array as PropType<ReportTableColumn[]>, required: true },
    rows: { type: Array as PropType<ReportTableRow[]>, required: true }
  },
  data: () => ({
    orderByField: "",
    orderByDirection: "asc" as "asc" | "desc",
    columnsToHide: [] as string[],
    columnsModalOpen: false,
    search: "",
    tableFullyVisible: false
  }),
  computed: {
    columnsToDisplay(): ReportTableColumn[] {
      return this.columns.filter(
        singleColumn =>
          !this.columnsToHide.some(hiddenColumn => hiddenColumn === singleColumn.field)
      );
    },
    fileName(): string {
      const currentTimestamp = format(new Date(), "yyyyMMdd-HHmm");
      return `${this.reportName}-${currentTimestamp}.csv`;
    },
    rowsWithoutHiddenColumns(): ReportTableRow[] {
      return this.rows.map(row => omit(row, this.columnsToHide));
    },
    rowSearch(): Fuse<ReportTableRow> {
      const keys = this.columnsToDisplay.map(col => col.field);
      return new Fuse(this.rowsWithoutHiddenColumns, { keys });
    },
    filteredRows(): ReportTableRow[] {
      return this.search
        ? this.rowSearch.search<ReportTableRow>(this.search).map(result => result.item)
        : this.rowsWithoutHiddenColumns;
    },
    sortedRows(): ReportTableRow[] {
      return this.orderByField
        ? orderBy(this.filteredRows, this.orderByField, this.orderByDirection)
        : this.filteredRows;
    }
  },
  methods: {
    openColumnsModal() {
      this.columnsModalOpen = true;
    },
    closeColumnsModal() {
      this.columnsModalOpen = false;
    },
    changeSortByField(field: string): void {
      console.log("changeSortByField", field);
      if (field === this.orderByField) {
        this.orderByDirection = this.orderByDirection === "asc" ? "desc" : "asc";
      } else {
        this.orderByDirection = "asc";
        this.orderByField = field;
      }
    },
    updateHiddenColumns(field: string, hide: boolean) {
      console.log("updateHiddenColumns", field, hide);
      if (hide) {
        this.columnsToHide.push(field);
      } else {
        this.columnsToHide = this.columnsToHide.filter(hiddenColumn => hiddenColumn !== field);
      }
      console.log(this.columnsToHide);
    },
    escapeDoubleQuotesInValue(value: string | number | boolean): string {
      let escapedValue = "";
      if (typeof value === "string") {
        escapedValue = value;
      } else if (value === undefined) {
        escapedValue = "";
      } else if (value === null) {
        escapedValue = "NULL";
      } else {
        escapedValue = value.toString();
      }
      return escapedValue.replace('"', '""');
    },
    csvEntry(value: string): string {
      let escapedValue = this.escapeDoubleQuotesInValue(value);
      const needsWrapped = /[",\n]/.test(value);
      if (needsWrapped) {
        escapedValue = `"${escapedValue}"`;
      }
      return `${escapedValue},`;
    },
    b64EncodeUnicode(str: string): string {
      return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1: string) {
          return String.fromCharCode(Number("0x" + p1));
        })
      );
    },
    getCSVData() {
      let csv = "";
      this.columnsToDisplay.forEach(col => {
        csv += this.csvEntry(col.title);
      });
      csv += "\n";
      this.sortedRows.forEach(row => {
        this.columnsToDisplay.forEach(col => {
          csv += this.csvEntry(row[col.field] || "");
        });
        csv += "\n";
      });
      return `data:text/csv;base64,${this.b64EncodeUnicode(csv)}`;
    }
  }
});
